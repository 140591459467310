@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";
@import "bootstrap/vendor/rfs";

// Initialize
@import "variables";
@import "mixins";

// Core CSS
@import "reset";
@import "helpers";
@import "layouts";

// Content Blocks
@import "header";
@import "elements";
@import "footer";
@import "extras";
@import url("https://fonts.googleapis.com/css2?family=Comfortaa&family=Oleo+Script+Swash+Caps&display=swap");
